import customAxios from "./axios";
import {
    SHIPMENT_V1,
    SHIPMENT_BY_ID_V1,
    SHIPMENT_ORDERS_BY_SHIPMENT_ID_V1,
    WAREHOUSE_TRANSFERS_BY_SHIPMENT_ID_V1,
    SHIPMENT_GENERAL_PICKLIST_IS_OPEN_V1,
    SHIPMENT_ORDER_BY_ID_V1,
    SHIPMENT_WAREHOUSE_GROUPS_BY_SHIPMENT_ID_V1,
    SHIPMENT_WAREHOUSE_GROUP_BY_ID_V1,
    SHIPMENT_ORDER_GENERAL_PICKLIST_BY_ID_V1,
    SHIPMENT_ORDER__EXPORT_GENERAL_PICKLIST_BY_ID_V1,
    SHIPMENT_GENERAL_PICKLIST_IS_OPEN_V2, SHIPMENT_BY_ID_V2,
} from "../constants/api";

export function getAll(param) {
    return customAxios.get(SHIPMENT_V1, {params: param});
}

export function getShipmentById(id) {
    return customAxios.get(SHIPMENT_BY_ID_V1(id));
}

export function createShipmentOrder(id, data) {
    return customAxios.post(SHIPMENT_BY_ID_V1(id), data);
}

export function getShipmentOrdersByShipmentId(id, params) {
    return customAxios.get(SHIPMENT_ORDERS_BY_SHIPMENT_ID_V1(id), { params });
}

export function getWarehouseTransfersByShipmentId(id) {
    return customAxios.get(WAREHOUSE_TRANSFERS_BY_SHIPMENT_ID_V1(id));
}

export function getPicklistIsOpen(param) {
    return customAxios.get(SHIPMENT_GENERAL_PICKLIST_IS_OPEN_V1, {params: param});
}

export function createShipmentOrderV2(id) {
    return customAxios.post(SHIPMENT_BY_ID_V2(id));
}

export function getPicklistIsOpenV2(id) {
    return customAxios.get(SHIPMENT_GENERAL_PICKLIST_IS_OPEN_V2(id));
}

export function getShipmentOrderById(id) {
    return customAxios.get(SHIPMENT_ORDER_BY_ID_V1(id));
}

export function getShipmentWarehouseGroups(shipmentId) {
    return customAxios.get(SHIPMENT_WAREHOUSE_GROUPS_BY_SHIPMENT_ID_V1(shipmentId));
}

export function createShipmentWarehouseGroup(shipmentId, data) {
    return customAxios.post(SHIPMENT_WAREHOUSE_GROUPS_BY_SHIPMENT_ID_V1(shipmentId), data);
}

export function deleteShipmentWarehouseGroup(warehouseGroupId) {
    return customAxios.delete(SHIPMENT_WAREHOUSE_GROUP_BY_ID_V1(warehouseGroupId));
}

export function getShipmentOrderPicklists(shipmentId, param) {
    return customAxios.get(SHIPMENT_ORDER_GENERAL_PICKLIST_BY_ID_V1(shipmentId), { params: param });
}

export function exportAndGetPicklistCsv(shipmentId, param) {
    return customAxios.get(SHIPMENT_ORDER__EXPORT_GENERAL_PICKLIST_BY_ID_V1(shipmentId), { params: param });
}
