import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaExclamationCircle, FaTrash } from 'react-icons/fa';
import styles from '../style.module.scss';

const DeleteConfirmationModal = ({ isShow, onClose, onConfirm }) => {
    return (
        <Modal
            show={isShow}
            onHide={onClose}
            centered
            backdrop="static"
            className={styles['confirmation-modal']}
            size="md"
        >
            <div className={styles['modal-icon-header']}>
                <div className={styles['trash-icon-container']}>
                    <FaTrash className={styles['trash-icon']} />
                </div>
            </div>
            <Modal.Header className={styles['custom-header']} closeButton>
                <Modal.Title className={styles['modal-title']}>
                    Konfirmasi Hapus
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles['custom-body']}>
                <p className={styles['confirmation-question']}>
                    Apakah Anda yakin ingin menghapus warehouse group ini?
                </p>
                <div className={styles['warning-container']}>
                    <FaExclamationCircle className={styles['warning-icon']} />
                    <span className={styles['warning-text']}>Tindakan ini tidak dapat dibatalkan.</span>
                </div>
            </Modal.Body>
            <Modal.Footer className={styles['custom-footer']}>
                <div className={styles['button-group']}>
                    <Button className={styles.btnCancel} onClick={onClose}>
                        Batal
                    </Button>
                    <Button className={styles.btnOk} onClick={onConfirm}>
                        Hapus
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal;