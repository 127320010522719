import {Button, Modal} from "react-bootstrap";
import {AiFillExclamationCircle} from "react-icons/ai";
import commonStyles from "../style.module.scss";
import React from "react";

const RunShipmentPromptModal = ({show, onHide, onConfirm}) => {
    return <Modal
        size="m"
        centered
        show={show}
        onHide={onHide}
    >
        <Modal.Header closeButton={true}/>
        <Modal.Body>
            <div className="d-flex justify-content-center">
                <AiFillExclamationCircle size='18%' color='#FD8D25'/>
            </div>
            <div className='mt-2 mb-3' style={{ textAlign: 'center' }}>
                <b>Lanjut run shipment? </b> <br/>
                Picklist yang belum selesai akan di-close
            </div>
            <div className={'form-group d-flex justify-content-end'}>
                <Button
                    className={`btn mr-2 ${commonStyles.btnCancel}`}
                    onClick={onHide}
                >
                    Batal
                </Button>
                <Button
                    type={'button'}
                    className={`btn ${commonStyles.btnOk}`}
                    onClick={() => {
                        onConfirm()
                        onHide()
                    }}
                >
                    Simpan
                </Button>
            </div>
        </Modal.Body>
    </Modal>;
}

export default RunShipmentPromptModal;