import axios from "./axios";
import {PROMO_LABELS_BY_POS_OUTLET_V1, PROMO_LABELS_CHANGE_ACTIVITY_V1, PROMO_LABELS_DELETE_V1, PROMO_LABELS_DROPDOWN_V1, PROMO_LABELS_DUPLICATE_V1, PROMO_LABELS_ID_V1, PROMO_LABELS_MASS_CHANGE_ACTIVITY_V1, PROMO_LABELS_PAGED_V1, PROMO_LABELS_V1} from "../constants/api";

export function getAllPromoLabels({params}) {
    return axios.get(PROMO_LABELS_V1,{params});
}

export function getAllPromoLabelsPaged({params}) {
    return axios.get(PROMO_LABELS_PAGED_V1,{params});
}

export function getAllPromoLabelUsedInPosOutlet({params}) {
    return axios.get(PROMO_LABELS_BY_POS_OUTLET_V1,{params});
}

export function createPromoLabel({promoLabel}) {
    return axios.put(PROMO_LABELS_V1, promoLabel);
}

export function updatePromoLabel({id, promoLabel}) {
    return axios.put(PROMO_LABELS_ID_V1(id), promoLabel);
}

export function changeActivity({data}) {
    return axios.put(PROMO_LABELS_CHANGE_ACTIVITY_V1, data);
}

export function massChangeActivity({data}) {
    return axios.put(PROMO_LABELS_MASS_CHANGE_ACTIVITY_V1, data);
}

export function massDelete({data}) {
    return axios.post(PROMO_LABELS_DELETE_V1, data);
}

export function duplicate({id, data}) {
    return axios.post(PROMO_LABELS_DUPLICATE_V1(id), data);
}

export function dropdown() {
    return axios.get(PROMO_LABELS_DROPDOWN_V1);
}