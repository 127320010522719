import React from 'react';
import { Col, Row } from "react-bootstrap";
import "../style.module.scss"
import Select from "react-select";

const ShipmentSearchBar = (props) => {
    const { fetchShipments, warehouseTagGroupFunctionalityOptions } = props;

    return (
        <Row>
            <Col className={'col-sm-4'}>
                    <Select
                        onChange={(v, a) => {
                            fetchShipments(v?.value);
                        }}
                        isMulti={false}
                        isClearable={true}
                        options={warehouseTagGroupFunctionalityOptions}
                        placeholder={"Pilih Functionality"}
                        styles={{
                            menu: provided => ({ ...provided, zIndex: 9999 })
                        }}
                    />
            </Col>
        </Row>
    )
}


export default ShipmentSearchBar;