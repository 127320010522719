import { Button, Modal, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import ReactSelectForm from "../../../../../components/ReactSelectForm/ReactSelectForm";
import commonStyles from "../style.module.scss";
import { displayAlert } from "../../../../../redux/actions/notif";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shipmentApi from "../../../../../apis/shipment";
import _ from 'lodash';
import RunShipmentPromptModal from "./RunShipmentPromptModal";

const ModalBody = ({ values, isSubmitting, setFieldValue, onClose, selectedShipmentId, displayAlert }) => {
    const [warehouseTransferOptions, setWarehouseTransferOptions] = useState([]);

    useEffect(() => {
        fetchWarehouseTransferOptions();
    }, []);

    const fetchWarehouseTransferOptions = async () => {
        let warehouseTransfers = [];
        try {
            warehouseTransfers = (await shipmentApi.getWarehouseTransfersByShipmentId(selectedShipmentId)).data.data;
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' });
        }

        const warehouseTransferOptions = warehouseTransfers.map(e => ({ value: e.value, label: e.label }));
        setWarehouseTransferOptions(warehouseTransferOptions);
    };

    return (
        <Form>
            <div className={"row"}>
                <div className={`col`}>
                    <div className={"form-group"}>
                        <label style={{ marginBottom: '4px' }}><b>Pilih Dokumen Warehouse Transfer</b></label> <br/>
                        <label style={{ fontSize: '12px' }}>Dokumen warehouse transfer yang dipilih akan digunakan dalam pembagian picklist</label>
                        <ReactSelectForm
                            isMulti={true}
                            fieldName={'warehouseTransferIds'}
                            placeholder={'Pilih...'}
                            options={warehouseTransferOptions}
                            isClearable
                            setFieldValue={setFieldValue}/>
                    </div>
                </div>
            </div>
            <div className={'form-group d-flex justify-content-end'}>
                <Button
                    className={`btn mr-2 ${commonStyles.btnCancel}`}
                    disabled={isSubmitting}
                    onClick={onClose}
                >
                    {isSubmitting && <Spinner animation="border" variant="light" size="sm"/>}Batal
                </Button>
                <Button
                    type={'submit'}
                    className={`btn ${commonStyles.btnOk}`}
                    disabled={isSubmitting || _.isEmpty(values.warehouseTransferIds)}
                >
                    {isSubmitting && <Spinner animation={"border"} variant={"light"} size={"sm"}/>}Simpan
                </Button>
            </div>
        </Form>
    );
};

const RunShipmentModal = ({ isShow, selectedShipmentName, selectedShipmentId, onClose, closeModalAndRefreshOrders, displayAlert }) => {
    const [isShowRunShipmentPrompt, setIsShowRunShipmentPrompt] = useState(false);
    const [createShipmentOrderData, setCreateShipmentOrderData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const createShipmentOrder = async (values) => {
        const data = !!values
            ? {
                warehouseTransferIds: values.warehouseTransferIds,
            }
            : createShipmentOrderData;

        setIsSubmitting(true);
        setIsShowRunShipmentPrompt(false);

        try {
            await shipmentApi.createShipmentOrder(selectedShipmentId, data);
            closeModalAndRefreshOrders();
            setIsSubmitting(false);
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' });
            setIsSubmitting(false);
        }
    };

    const runShipment = async (values) => {
        let isPicklistOpen = false;

        try {
            const shipmentPickListParams = {
                warehouseTransferIds: values.warehouseTransferIds.join(','),
            };
            isPicklistOpen = (await shipmentApi.getPicklistIsOpen(shipmentPickListParams)).data.data;

            if (!isPicklistOpen) {
                createShipmentOrder(values);
            } else {
                setIsShowRunShipmentPrompt(true);
                setCreateShipmentOrderData({
                    warehouseTransferIds: values.warehouseTransferIds,
                });
            }
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' });
        }
    };

    return (
        <>
            <Modal
                size="m"
                centered
                show={isShow}
                onHide={onClose}
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title><b>Buat Shipment Order Untuk {selectedShipmentName}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            warehouseTransferIds: [],
                        }}
                        enableReinitialize={true}
                        onSubmit={runShipment}
                    >
                        {(formikProps) => (
                            <ModalBody
                                isSubmitting={isSubmitting}
                                selectedShipmentId={selectedShipmentId}
                                displayAlert={displayAlert}
                                onClose={onClose}
                                {...formikProps}
                            />
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            <RunShipmentPromptModal
                show={isShowRunShipmentPrompt}
                onHide={() => setIsShowRunShipmentPrompt(false)}
                onConfirm={() => createShipmentOrder()}
            />
        </>
    );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RunShipmentModal);