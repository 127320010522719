import React, { useState, useEffect } from 'react';
import { Col, Row } from "react-bootstrap";
import styles from "../style.module.scss";
import * as shipmentApi from "../../../../../apis/shipment.js";
import { getRunStatusWidget } from '../Components/RunStatusWidget.js'
import { TIMEZONE } from '../../../../../constants/time.js';
import moment from 'moment';
import ShipmentOrderDetailNavTab, { NAV_ITEM_IDS } from './ShipmentOrderDetailNavTab.js';
import ShipmentOrdersPicklistsSection from './ShipmentOrderPicklistsSection.js';
import { IoCopyOutline } from 'react-icons/io5';
import copy from "copy-to-clipboard";

const _ = require('lodash');
const ShipmentOrderDetailPage = (props) => {
    const { displayAlert, setSelectedShipmentId, selectedShipmentName, selectedShipmentOrderId, setSelectedShipmentOrderId } = props;
    const [shipmentOrderData, setShipmentOrderData] = useState(null);
    const [ activeNavItemId, setActiveNavItemId] = useState(NAV_ITEM_IDS.PICKLIST);
    const [ warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        const fetchShipmentOrderDetails = async () => {
            try {
                const data = (await shipmentApi.getShipmentOrderById(selectedShipmentOrderId)).data.data;
                setWarehouses(data?.warehouseDestinations ?? []);
                setShipmentOrderData(data);
            } catch (e) {
                displayAlert({message: e.message, type: 'error'});
            }
        }

        fetchShipmentOrderDetails();
    }, [selectedShipmentOrderId]);

    const Title = () => {
        return (
            <div className={styles.title}>Shipment Order Untuk {selectedShipmentName}</div>
        );
    }

    const Navigator = () => {
        return (
            <div className={styles.navigator}> <div className={styles.navigatorLink} onClick={(target) => { setSelectedShipmentId(null) }}> Semua Shipment</div> &nbsp; / &nbsp; <div className={styles.navigatorLink} onClick={(target) => { setSelectedShipmentOrderId(null) }}> {selectedShipmentName}</div> &nbsp; / &nbsp; {selectedShipmentOrderId}</div>
        );
    }

    const ShipmentInformation = () => {
        if (_.isNil(shipmentOrderData)) return null;

        return (
            <div className='ml-3 mb-3'>
                <Row>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                            RUN STATUS
                        </Row>
                        <Row style={{fontSize: '14px', color: '#050406'}}>
                            {
                                getRunStatusWidget(shipmentOrderData.status)
                            }
                        </Row>
                    </Col>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                WAKTU MULAI
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                {shipmentOrderData.runStartAt != null ? moment(shipmentOrderData.runStartAt).tz(TIMEZONE).format('DD/MM/YYYY') + ' pada ' + moment(shipmentOrderData.runStartAt).tz(TIMEZONE).format('HH:mm') :  '-'}
                            </Row>
                        </div>
                    </Col>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                DURASI
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                { shipmentOrderData.duration != null ? `${shipmentOrderData.duration} menit` : '-' }
                            </Row>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                RUN ID
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                {shipmentOrderData.id}
                                <div className={[styles.hoverable, 'align-top ml-1'].join(' ')} style={{display: 'inline', margin: '0', padding: '0', border: '0', minHeight: '0', minWidth: '0'}} onClick={() => { copy(`${shipmentOrderData.id}`)}}> <IoCopyOutline size='0.875rem'/> </div>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    let shipmentDetailBody;

    if (activeNavItemId === NAV_ITEM_IDS.PICKLIST) {
        shipmentDetailBody = <ShipmentOrdersPicklistsSection selectedShipmentOrderId={selectedShipmentOrderId} warehouses={warehouses} selectedShipmentName={selectedShipmentName}/>
    }

    return (
        <div className={"w-100 h-100 overflow-auto"}>
            <Navigator/>
            <Title/>
            <ShipmentInformation/>
            <ShipmentOrderDetailNavTab activeNavItemId={activeNavItemId} setActiveNavItemId={setActiveNavItemId}/>
            { shipmentDetailBody }
        </div>
    );
}


export default ShipmentOrderDetailPage;