import React, { useEffect, useState } from "react";
import { Modal, Spinner, Button, FormCheck } from "react-bootstrap";
import { Form, Formik } from "formik";
import DatePicker from "react-datepicker";

import Select from "react-select";

import { CSVLink } from "react-csv";
import { getDivisionOptions, generatePayroll } from "../../../../apis/ManpowerDatabase";
import moment from "moment";
import { Input } from "antd";


const GeneratePayRollModalComponent = (props) => {
    const { isShown, toggleModal } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [isUploadSuccess, setIsUploadSucess] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedDivision, setSelectedDivision] = useState(null);

    const [payrollFilename, setPayrollFileName] = useState(null);
    const [payrollCSVData, setPayrollCsvData] = useState([]);

    const [divisionOptions, setDivisionOptions] = useState([]);

    const [isChecked, setIsChecked] = useState(false);

    const [showErrorTable, setShowErrorTable] = useState(false);

    const isKula = props.isKula ?? false;

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    useEffect(() => {
        fetchDivisionOptions()
    }, [])

    const fetchDivisionOptions = async () => {
        const result = await getDivisionOptions();
        const options = result?.data?.data;
        if (options && options.length) {
            setDivisionOptions(options)
        }
    }

    const submitGeneratePayroll = async () => {
        try {
            setSubmitting(true);

            const divisionId = selectedDivision?.value;
            const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
            const formattedSelectedMonth = moment(selectedMonth).format('YYYY-MM-DD');
            const result = await generatePayroll({
                monthOfPayroll: formattedSelectedMonth,
                effectiveDate: formattedDate,
                workplaceDepartmentId: isKula ? null : divisionId,
                isIncludeMissingData: isChecked,
                isFromKula: isKula
            })

            const payrollResult = result.data.data;

            setIsUploadSucess(payrollResult.success)
            generatePayrollCSV(payrollResult.result)
            generateErrorData(payrollResult.errors)
        } catch (e) {
            handleOnClear();
            alert(e.message)
        }
        finally {
            setSubmitting(false);
        }
    }

    const renderSuccessStatus = () => {
        if (isSubmitting) {
            return <Spinner animation={'border'} size={'sm'} />
        }
        else {
            if (isUploadSuccess) {
                return <label className={'font-weight-bold text-success'}>
                    Success
                </label>
            }
        }

        return <label>
            Generate Terlebih Dahulu
        </label>
    }

    const renderResultFeedback = () => {        
        const payrollCsvFilename = `${payrollFilename}.csv`;
        
        if (isUploadSuccess && payrollCSVData) {
            if (payrollCSVData.length > 0) {
                return <CSVLink
                    data={payrollCSVData}
                    filename={payrollCsvFilename}
                >
                    Download Payroll
                </CSVLink>
            }
            else {
                return <p>Data Payroll divisi ini kosong</p>
            }
        }

        return <p>-</p>
    }

    const renderErrorList = () => {
        return <>
            <p>{errorData.length} employees tidak di-include dalam payroll.</p>
            <button className="btn btn-primary" onClick={() => setShowErrorTable(!showErrorTable)}>
                {showErrorTable ? "Sembunyikan" : "Tampilkan"}
            </button>

            {
                showErrorTable && <table className="table" id="errorTable">
                    <thead>
                        <tr>
                            <th>No</th>
                            <th>NIK</th>
                            <th>Nama</th>
                            <th>Alasan</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            errorData.map((error, index) => {
                                return <tr>
                                    <td>{index + 1}</td>
                                    <td>{error.employeeId}</td>
                                    <td>{error.name}</td>
                                    <td>{error.message}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            }
        </>
    }

    const generatePayrollCSV = async (listOfPayroll) => {
        const headerMapping = {
            effectiveDate: "Effective Date",
            trxId: "Trx ID",
            transferType: "Transfer Type",
            debitAccount: "Debited Account",
            beneficiaryId: "Beneficiary ID",
            creditAccount: "Credited Account",
            amount: "Amount",
            trxPuose: "Trx Puose",
            currency: "Currency",
            chargesType: "Charges Type",
            chargesAccount: "Charges Account",
            remarkOne: "Remark 1",
            remarkTwo: "Remark 2",
            rcvBankCode: "Rcv Bank Code",
            rcvBankName: "Rcv Bank Name",
            rcvName: "Rcv Name",
            custType: "Cust Type",
            custResidence: "Cust Residence",
            trxCode: "Trx Code",
            email: "Email"
        };
        
        const headers = [
            "Effective Date",
            "Trx ID",
            "Transfer Type",
            "Debited Account",
            "Beneficiary ID",
            "Credited Account",
            "Amount",
            "Trx Puose",
            "Currency",
        	"Charges Type",
            "Charges Account",
            "Remark 1",
            "Remark 2",
            "Rcv Bank Code",
            "Rcv Bank Name",
            "Rcv Name",
            "Cust Type",
            "Cust Residence",
            "Trx Code",
            "Email",
        ]

        if (!listOfPayroll || !listOfPayroll.length) return [];

        const csvData = [];

        csvData.push(headers);

        listOfPayroll.forEach(item => {
            const row = headers.map(header => {
                const key = Object.keys(headerMapping).find(key => headerMapping[key] === header);
                return item[key] || ""; 
            });
            csvData.push(row);
        });

        setPayrollCsvData(csvData);
    }

    const generateErrorData = async (errors) => {
        const errorData = errors.map(error => {
            const {employeeId, name, message} = error;

            return {
                employeeId,
                name,
                message,
            };
        });

        setErrorData(errorData);
    }
    
    const handleOnClear = () => {
        setSelectedDate(null)
        setSelectedMonth(new Date())
        setSelectedDivision(null)
        setErrorData(null)
        setPayrollCsvData(null)
        setIsChecked(false)
        setSubmitting(false)
        setIsUploadSucess(false)
    }

    return (
        <Modal size='lg' show={isShown} onHide={() => { handleOnClear(); toggleModal(false) }}>
            <Modal.Header closeButton>
                <Modal.Title>Generate Payroll</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik initialValues={{}}>
                    {({ setFieldValue, values }) => (
                        <Form>
                            <div className="form-group">
                                <label>Month of Payroll</label>
                                <DatePicker
                                    selected={selectedMonth}
                                    onChange={(date) => {
                                        setSelectedMonth(date);
                                        setSelectedDate(null); // Reset the selected date when month changes
                                    }}
                                    dateFormat="MMMM yyyy"
                                    showMonthYearPicker
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Effective Date</label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="dd MMMM yyyy"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Division</label>
                                <Select
                                    isDisabled={isKula}
                                    isMulti={false}
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                    className={'mb-3'}
                                    options={
                                        divisionOptions.map((el) => {
                                            return {
                                                value: el.id,
                                                label: el.name
                                            };
                                        })}
                                    defaultValue={isKula ? { label: 'KULA' } : selectedDivision }
                                    onChange={(el) => {
                                        setSelectedDivision(el)
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label>File Name</label>
                                <Input
                                    className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value != null) {
                                            setPayrollFileName(e.target.value);
                                        }
                                    }}
                                />
                            </div>
                            <div className={"form-group d-flex row"}>
                                <p className={"mx-3"}>Include user with missing data</p>
                                <FormCheck
                                    checked={isChecked}
                                    type={'checkbox'}
                                    onChange={handleCheckboxChange}
                                />
                            </div>

                            <div className="form-group d-flex mt-2">
                                <div className="form-control" style={{ width: 340, flex: 'none' }}>
                                    {renderSuccessStatus()}
                                </div>
                                <div className="form-control flex-grow-1 ml-2">
                                    {renderResultFeedback()}
                                </div>
                            </div>

                            { errorData?.length > 0 && renderErrorList() }

                        </Form>
                    )}
                </Formik>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex">
                    <Button
                        disabled={false}
                        variant="danger" onClick={() => {
                            handleOnClear()
                            toggleModal(false)
                        }}>
                        Tutup
                    </Button>
                    <Button
                        className="ml-2"
                        disabled={(!isKula && !selectedDivision) || !selectedMonth || !selectedDate || !payrollFilename || isSubmitting}
                        variant="primary"
                        onClick={() => {
                            setErrorData(null)
                            setIsUploadSucess(false)
                            setPayrollCsvData([])
                            submitGeneratePayroll()
                        }}>
                        {isSubmitting && <Spinner animation="border" variant="light" size="sm" />} Generate Payroll
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default GeneratePayRollModalComponent;
