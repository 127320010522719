import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {Col, Row, Spinner} from "react-bootstrap";
import styles from "../style.module.scss";
import * as shipmentApi from "../../../../../apis/shipment.js";
import ShipmentDetailNavTab, { NAV_ITEM_IDS } from './ShipmentDetailNavTab.js';
import ShipmentDetailOrdersListSection from './ShipmentDetailOrdersListSection.js';
import { FaPlay } from 'react-icons/fa';
import RunShipmentModal from './RunShipmentModal.js';
import ShipmentDetailWarehouseGroupConfigurationSection from './ShipmentDetailWarehouseGroupConfigurationSection.js';
import ShipmentOrderDetailPage from '../ShipmentOrderDetail/ShipmentOrderDetailPage.js';
import { IoCopyOutline } from "react-icons/io5";
import copy from "copy-to-clipboard";
import RunShipmentPromptModal from "./RunShipmentPromptModal";

const ITEMS_PER_PAGE = 10;

const ShipmentDetailPage = (props) => {
    const {
        displayAlert,
        selectedShipmentId,
        setSelectedShipmentId,
        selectedShipmentName,
        initialSelectedShipmentOrderId,
        setSelectedShipmentOrderId: parentSetSelectedShipmentOrderId
    } = props;

    const [shipmentData, setShipmentData] = useState(null);
    const [isShowRunShipmentPrompt, setIsShowRunShipmentPrompt] = useState(false);
    const [activeNavItemId, setActiveNavItemId] = useState(NAV_ITEM_IDS.RUNS);
    const [showRunShipmentModal, setShowRunShipmentModal] = useState(false);
    const [shipmentOrders, setShipmentOrders] = useState([]);
    const [shipmentOrdersPageData, setShipmentOrdersPageData] = useState({
        totalPages: 1,
        totalElements: 0,
        currentPage: 0,
        size: ITEMS_PER_PAGE,
    });
    const [selectedShipmentOrderId, setSelectedShipmentOrderId] = useState(initialSelectedShipmentOrderId || null);
    const [isFetchingShipmentOrders, setIsFetchingShipmentOrders] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const displayName = useMemo(() =>
            selectedShipmentName || (shipmentData && shipmentData.name) || 'Loading...',
        [selectedShipmentName, shipmentData]
    );

    const createShipmentOrderFlow2 = async () => {
        try {
            const response = await shipmentApi.createShipmentOrderV2(selectedShipmentId);
            const message = response?.data?.data;
            if (message && typeof message === 'string' && message.trim() !== '') {
                displayAlert({ message, type: 'info' });
            }
            fetchShipmentOrders(1).then();
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' });
        }
    }

    const updateSelectedShipmentOrderId = useCallback((orderId) => {
        setSelectedShipmentOrderId(orderId);
        if (parentSetSelectedShipmentOrderId) {
            parentSetSelectedShipmentOrderId(orderId);
        }
    }, [parentSetSelectedShipmentOrderId]);

    const onClickRun = useCallback(async () => {
        const flowVersion = shipmentData?.flowVersion;
        if (flowVersion === 1) {
            return setShowRunShipmentModal(true)
        }
        if (flowVersion === 2) {
            setIsLoading(true);
            let isPicklistOpen = false;
            try {
                isPicklistOpen = (await shipmentApi.getPicklistIsOpenV2(selectedShipmentId)).data.data;
                if (!isPicklistOpen) {
                    setIsShowRunShipmentPrompt(false);
                    await createShipmentOrderFlow2();
                } else {
                    setIsShowRunShipmentPrompt(true);
                }
            } catch (e) {
                displayAlert({ message: e.message, type: 'error' });
            } finally {
                setIsLoading(false);
            }
        }
    }, [shipmentData?.flowVersion, selectedShipmentId, displayAlert]);

    const handleCopyId = useCallback(() => {
        if (shipmentData?.id) {
            copy(shipmentData.id);
        }
    }, [shipmentData?.id]);

    const fetchShipmentOrders = useCallback(async (page) => {
        if (!selectedShipmentId) return;

        setIsFetchingShipmentOrders(true);

        const pageWithOffset = page - 1;
        const params = { page: pageWithOffset };

        try {
            const response = await shipmentApi.getShipmentOrdersByShipmentId(selectedShipmentId, params);
            const shipmentOrdersData = response.data.data;

            setShipmentOrdersPageData(prev => ({
                ...prev,
                currentPage: page,
                totalPages: shipmentOrdersData.totalPages,
                totalElements: shipmentOrdersData.totalElements,
            }));

            setShipmentOrders(shipmentOrdersData.data);
        } catch (e) {
            displayAlert({message: e.message, type: 'error'});
        } finally {
            setIsFetchingShipmentOrders(false);
        }
    }, [selectedShipmentId, displayAlert]);

    const fetchShipmentDetails = useCallback(async () => {
        if (!selectedShipmentId) return;

        try {
            const response = await shipmentApi.getShipmentById(selectedShipmentId);
            setShipmentData(response.data.data);
        } catch (e) {
            displayAlert({message: e.message, type: 'error'});
        }
    }, [selectedShipmentId, displayAlert]);

    useEffect(() => {
        fetchShipmentDetails().then();
    }, []);

    const closeRunShipmentModalAndRefreshShipmentOrders = useCallback(() => {
        setShowRunShipmentModal(false);
        fetchShipmentOrders(1).then();
    }, [fetchShipmentOrders]);

    const Navigator = useMemo(() => (
        <div className={styles.navigator}>
            <div className={styles.navigatorLink} onClick={() => setSelectedShipmentId()}>
                Semua Shipment
            </div> &nbsp; / &nbsp; {displayName}
        </div>
    ), [displayName, setSelectedShipmentId]);

    const ShipmentInformation = useMemo(() => {
        if (!shipmentData) {
            return <div className='ml-3 mb-3'>Loading shipment information...</div>;
        }

        return (
            <div className='ml-3 mb-3'>
                <Row>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                            JADWAL
                        </Row>
                        <Row style={{fontSize: '14px', color: '#050406'}}>
                            On Demand
                        </Row>
                    </Col>
                    <Col md={2} style={{borderRight: '1px solid #A8AAAD'}}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                SHIPMENT ID
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                {shipmentData?.id}
                                {shipmentData?.id && (
                                    <div
                                        className={[styles.hoverable, 'align-top ml-1'].join(' ')}
                                        style={{display: 'inline', margin: '0', padding: '0', border: '0', minHeight: '0', minWidth: '0'}}
                                        onClick={handleCopyId}
                                    >
                                        <IoCopyOutline size='0.875rem'/>
                                    </div>
                                )}
                            </Row>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='pl-2'>
                            <Row style={{fontSize: '14px', color: '#767676', fontWeight: '500'}}>
                                WAREHOUSE ASAL
                            </Row>
                            <Row style={{fontSize: '14px', color: '#050406'}}>
                                {shipmentData?.warehouseSource}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }, [shipmentData, handleCopyId]);

    if (selectedShipmentOrderId) {
        return (
            <ShipmentOrderDetailPage
                displayAlert={displayAlert}
                selectedShipmentName={displayName}
                setSelectedShipmentId={setSelectedShipmentId}
                setSelectedShipmentOrderId={updateSelectedShipmentOrderId}
                shipmentData={shipmentData}
                selectedShipmentOrderId={selectedShipmentOrderId}
            />
        );
    }

    const renderDetailBody = () => {
        switch (activeNavItemId) {
            case NAV_ITEM_IDS.RUNS:
                return (
                    <ShipmentDetailOrdersListSection
                        selectedShipmentId={selectedShipmentId}
                        shipmentOrders={shipmentOrders}
                        fetchShipmentOrders={fetchShipmentOrders}
                        pageData={shipmentOrdersPageData}
                        setSelectedShipmentOrderId={updateSelectedShipmentOrderId}
                        isLoading={isFetchingShipmentOrders}
                    />
                );
            case NAV_ITEM_IDS.CONFIGURATION:
                return (
                    <ShipmentDetailWarehouseGroupConfigurationSection
                        selectedShipmentId={selectedShipmentId}
                        displayAlert={displayAlert}
                        shipmentData={shipmentData}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="w-100 h-100 overflow-auto">
            {Navigator}
            <Row className='justify-content-between'>
                <Col>
                    <div className={styles.title}>{displayName}</div>
                </Col>
                <Col sm={2} className='align-content-center text-center'>

                    <button
                        disabled={isLoading}
                        className={['btn', styles.btnOutlineOk].join(' ')}
                        style={{display: 'flex', alignItems: 'center', margin: '0 auto', padding: '2px 16px'}}
                        type='button'
                        onClick={() => onClickRun()}
                    >
                        {isLoading
                            ? <Spinner style={{fontSize: '0.8rem', marginTop: '0', marginBottom: '0', marginRight: '0.4em'}} animation={'border'} size={'sm'}/>
                            : <FaPlay style={{fontSize: '0.8rem', marginTop: '0', marginBottom: '0', marginRight: '0.4em'}}/>
                        }
                        <div className='text-center' style={{fontSize: '0.875rem', display: 'inline-block'}}>Run</div>
                    </button>
                </Col>
            </Row>
            {ShipmentInformation}
            <ShipmentDetailNavTab activeNavItemId={activeNavItemId} setActiveNavItemId={setActiveNavItemId}/>
            {renderDetailBody()}
            <RunShipmentModal
                flowVersion={shipmentData?.flowVersion}
                isShow={showRunShipmentModal}
                selectedShipmentId={selectedShipmentId}
                selectedShipmentName={displayName}
                onClose={() => setShowRunShipmentModal(false)}
                closeModalAndRefreshOrders={closeRunShipmentModalAndRefreshShipmentOrders}
                displayAlert={displayAlert}
            />
            <RunShipmentPromptModal
                show={isShowRunShipmentPrompt}
                onHide={() => setIsShowRunShipmentPrompt(false)}
                onConfirm={() => {
                    setIsLoading(true);
                    createShipmentOrderFlow2().then(r => setIsLoading(false));
                }}
            />
        </div>
    );
};

export default ShipmentDetailPage;