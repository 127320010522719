import React, { useState, useEffect } from 'react';
import * as warehouseTagGroupApi from "../../../../apis/warehouseTagGroup.js";
import * as shipmentApi from "../../../../apis/shipment.js";
import ShipmentListPage from './ShipmentList/ShipmentListPage.js';
import ShipmentDetailPage from './ShipmentDetail/ShipmentDetailPage.js';
import { ACTIVE_LINKS } from "../navigation-link";

const ShipmentPage = (props) => {
    const [warehouseTagGroupData, setWarehouseTagGroupData] = useState([]);
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);
    const [selectedShipmentName, setSelectedShipmentName] = useState(null);
    const [selectedShipmentOrderId, setSelectedShipmentOrderId] = useState(null);

    useEffect(() => {
        const path = props.location?.pathname || "";
        const shipmentOrderPattern = /\/shipment\/(?<shipmentId>\w+)\/order\/(?<orderId>\w+)/;
        const shipmentPattern = /\/shipment\/(?<shipmentId>\w+)/;
        const orderMatches = path.match(shipmentOrderPattern);
        const shipmentMatches = path.match(shipmentPattern);

        if (orderMatches?.groups) {
            const { shipmentId, orderId } = orderMatches.groups;
            setSelectedShipmentId(shipmentId);
            setSelectedShipmentOrderId(orderId);
            fetchShipmentName(shipmentId);
        } else if (shipmentMatches?.groups) {
            const { shipmentId } = shipmentMatches.groups;
            setSelectedShipmentId(shipmentId);
            setSelectedShipmentOrderId(null);
            fetchShipmentName(shipmentId);
        } else if (selectedShipmentId && !path.includes(`/shipment/${selectedShipmentId}`)) {
            setSelectedShipmentId(null);
            setSelectedShipmentName(null);
            setSelectedShipmentOrderId(null);
        }
    }, [props.location]);

    useEffect(() => {
        if (selectedShipmentId) {
            let newUrl = `${ACTIVE_LINKS.SHIPMENT}/${selectedShipmentId}`;
            if (selectedShipmentOrderId) {
                newUrl += `/order/${selectedShipmentOrderId}`;
            }

            if (window.location.pathname !== newUrl) {
                window.history.pushState(null, "", newUrl);
            }
        }
    }, [selectedShipmentId, selectedShipmentOrderId]);

    useEffect(() => {
        if (warehouseTagGroupData.length === 0) {
            warehouseTagGroupApi.getAll()
                .then(result => setWarehouseTagGroupData(result?.data?.data))
                .catch(e => props.displayAlert({ message: e.message, type: "error" }));
        }
    }, []);

    const fetchShipmentName = async (shipmentId) => {
        try {
            const result = await shipmentApi.getShipmentById(shipmentId);
            setSelectedShipmentName(result?.data?.data?.name);
        } catch (e) {
            props.displayAlert({ message: e.message, type: "error" });
        }
    };

    const handleShipmentSelect = (shipmentId, shipmentName) => {
        setSelectedShipmentId(shipmentId);
        setSelectedShipmentName(shipmentName);
        setSelectedShipmentOrderId(null);
    };

    const handleBackToList = () => {
        setSelectedShipmentId(null);
        setSelectedShipmentName(null);
        setSelectedShipmentOrderId(null);
        window.history.pushState(null, "", ACTIVE_LINKS.SHIPMENT);
    };

    if (selectedShipmentId) {
        return (
            <ShipmentDetailPage
                displayAlert={props.displayAlert}
                setSelectedShipmentId={handleBackToList}
                selectedShipmentId={selectedShipmentId}
                selectedShipmentName={selectedShipmentName}
                initialSelectedShipmentOrderId={selectedShipmentOrderId}
                setSelectedShipmentOrderId={setSelectedShipmentOrderId}
            />
        );
    }

    return (
        <ShipmentListPage
            displayAlert={props.displayAlert}
            setSelectedShipmentId={handleShipmentSelect}
            setSelectedShipmentName={setSelectedShipmentName}
        />
    );
};

export default ShipmentPage;