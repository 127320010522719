export const ORDER = 'order';
export const ORDER_CONFIGURE = 'order_configure';
export const ORDER_CONFIRM = 'order_confirm';
export const ORDER_REFUND = 'order_refund';
export const CREATE_ORDER_REFUND_PRODUCT_OPS = 'created_order_products_refund_ops';
export const CREATE_ORDER_REFUND_PRODUCT_CF = 'created_order_products_refund_cf';
export const DELETE_ORDER_REFUND_PRODUCT_OPS = 'delete_order_products_refund_ops';
export const DELETE_ORDER_REFUND_PRODUCT_CF = 'delete_order_products_refund_cf';
export const ORDER_RESCUE = 'order_rescue';
export const CANCEL_UNPAID_ORDERS = 'cancel_unpaid_orders';
export const NOTIFY_OUT_OF_STOCK_ORDERS = 'notify_out_of_stock_orders';

export const FAILED_DELIVERY_UPLOAD = "failed_delivery_upload";

export const SIMULATE_REFUND = "simulate_refund";

/************************************************************/

export const AGENT_CREATE = 'agent_create';
export const AGENT_UPDATE = 'agent_update';
export const AGENT_LIST = 'agent_list';
export const AGENT_COMMISSION_LIST = 'agent_commission_list';
export const AGENT_COMMISSION_CONFIGURE = 'agent_commission_configure';
export const AGENT_GMV_LIST = 'agent_gmv_list';
export const AGENT_WORKDAY_LIST = 'agent_workday_list';
export const AGENT_WORKDAY_CONFIGURE = 'agent_workday_configure';
export const AGENT_ACTIVATION = 'agent_activation';
export const AGENT_CREDENTIALS = 'agent_credentials';
export const AGENT_BULK_UPDATE_INFO = 'bulk_update_agent_info';
/************************************************************/

export const AGENT_SLUG_CREATE = 'agent_slug_create';
export const AGENT_SLUG_LIST = 'agent_slug_list';
export const AGENT_SLUG_DELETE = 'agent_slug_delete';

/************************************************************/

export const PRICE_GROUP_CREATE = 'price_group_create';
export const PRICE_GROUP_UPDATE = 'price_group_update';
export const PRICE_GROUP_LIST = 'price_group_list';

/************************************************************/

export const FLASH_SALE_CONFIGURE = 'flash_sale_configure';
export const FLASH_SALE_LIST = 'flash_sale_list';

/************************************************************/

export const PRICE_GROUP_PRODUCT_CREATE = 'price_group_product_create';
export const PRICE_GROUP_PRODUCT_UPDATE = 'price_group_product_update';
export const PRICE_GROUP_PRODUCT_DELETE = 'price_group_product_delete';
export const PRICE_GROUP_PRODUCT_LIST = 'price_group_product_list';
export const PRICE_GROUP_PRODUCT_COPY_LIST = 'price_group_product_copy_list';
export const PRICE_GROUP_PRODUCT_COPY_CONFIGURE = 'price_group_product_copy_list';

/************************************************************/

export const CREATE_PRODUCTS = 'create_products';
export const UPDATE_PRODUCTS_GENERAL_INFO = 'update_products_general_info';
export const UPDATE_PRODUCTS_CORE_INFO = 'update_products_core_info';
export const UPDATE_PRODUCTS_MARKETING_INFO = 'update_products_marketing_info';
export const UPDATE_PRODUCTS_DOWNSTREAM_INFO = 'update_products_downstream_info';
export const UPDATE_PRODUCTS_QUALITY_INFO = 'update_products_quality_info';
export const PRODUCT_LIST = 'product_list';
export const PRODUCT_WITH_PRICE_LIST = 'product_with_price_list';
export const PRODUCT_DATA_ACCOUNTING = 'product_data_accounting';

// Deprecated, replaced by CREATE_PRODUCTS
export const PRODUCT_CONFIGURE = 'product_configure';

/************************************************************/

export const PRODUCT_ORDER_LIMIT_LIST = 'product_order_limit_list';
export const PRODUCT_ORDER_LIMIT_CONFIGURE = 'product_order_limit_configure';
export const VIEW_AUTO_PRODUCT_LIMITER = 'view_automatic_product_limiter';
export const CONFIGURE_AUTO_PRODUCT_LIMITER = 'configure_automatic_product_limiter';

/************************************************************/

export const PRODUCT_KEYWORDS_CONFIGURE = 'product_keywords_configure';

/************************************************************/

export const PURCHASING_PRODUCT_LIST = 'purchasing_product_list';
export const PURCHASING_PRODUCT_CREATE = 'purchasing_product_create';
export const PURCHASING_PRODUCT_UPDATE = 'purchasing_product_update';

/************************************************************/

export const PRODUCT_PURCHASING_PRODUCT_LIST = 'product_purchasing_product_list';
export const PRODUCT_PURCHASING_PRODUCT_CREATE = 'product_purchasing_product_create';
export const PRODUCT_PURCHASING_PRODUCT_UPDATE = 'product_purchasing_product_update';
export const PRODUCT_PURCHASING_PRODUCT_DELETE = 'product_purchasing_product_delete';

/************************************************************/

export const PRODUCT_TAG_LIST = 'product_tag_list';
export const PRODUCT_TAG_CREATE = 'product_tag_create';
export const PRODUCT_TAG_UPDATE = 'product_tag_update';
export const PRODUCT_TAG_DELETE = 'product_tag_delete';

/************************************************************/

export const LIST_UNIT_OF_MEASURE = "list_unit_of_measure";
export const LIST_ITEM_SUGGESTION_UNIT_OF_MEASURE = "list_item_suggestion_unit_of_measure";

/************************************************************/

export const FRONTEND_SUBCATEGORY_LIST = "frontend_subcategory_list";
export const FRONTEND_SUBCATEGORY_CREATE = "frontend_subcategory_create";
export const FRONTEND_SUBCATEGORY_UPDATE = "frontend_subcategory_update";
export const FRONTEND_SUBCATEGORY_DELETE = "frontend_subcategory_delete";

/************************************************************/

export const FRONTEND_CATEGORY_LIST = 'frontend_category_list';
export const FRONTEND_CATEGORY_CREATE = 'frontend_category_create';
export const FRONTEND_CATEGORY_UPDATE = 'frontend_category_update';
export const FRONTEND_CATEGORY_DELETE = 'frontend_category_delete';

/************************************************************/

export const FRONTEND_CATEGORY_GROUP_LIST = 'frontend_category_group_list';
export const FRONTEND_CATEGORY_GROUP_CREATE = 'frontend_category_group_create';
export const FRONTEND_CATEGORY_GROUP_UPDATE = 'frontend_category_group_update';
export const FRONTEND_CATEGORY_GROUP_LOG = 'frontend_category_group_log';

/************************************************************/

export const ORDER_LIST = 'order_list';

/************************************************************/

export const ORDER_PRODUCT_LIST = 'order_product_list';
export const ORDER_PRODUCT_UPDATE = 'order_product_update';
export const ORDER_PRODUCT_PURCHASING_LIST = 'order_product_purchasing_list';
export const ORDER_PRODUCT_FREEBIE_LIST = 'order_product_freebie_list';

/************************************************************/

export const INVOICE_LIST = 'invoice_list';
export const INVOICE_CONFIGURE = 'invoice_configure';

/************************************************************/

export const PROMO_LIST = 'promo_list';
export const PROMO_CREATE = 'promo_create';
export const PROMO_UPDATE = 'promo_update';
export const PROMO_BLACKLIST = 'promo_blacklist';
export const PROMO_BLACKLIST_CONFIGURE = 'promo_blacklist_configure';
export const PROMO_VIEW = 'promo_view';
export const COMPLIMENTARY_VOUCHER = 'complimentary_voucher';

/************************************************************/

export const AGENT_FEATURE_FLAG_LIST = 'agent_feature_flag_list';
export const AGENT_FEATURE_FLAG_CONFIGURE = 'agent_feature_flag_configure';

/************************************************************/

export const WHATSAPP_TEMPLATE_LIST = 'whatsapp_template_list';
export const WHATSAPP_TEMPLATE_CONFIGURE = 'whatsapp_template_configure';
export const WHATSAPP_NOTIFY_DELIVERY_LIST = 'whatsapp_notify_delivery_list';
export const WHATSAPP_NOTIFY_DELIVERY_CONFIGURE = 'whatsapp_notify_delivery_configure';
export const WHATSAPP_OUTBOUND_LOG_LIST = 'whatsapp_outbound_log_list';

/************************************************************/

export const WAREHOUSE_LIST = 'warehouse_list';
export const WAREHOUSE_CREATE = 'warehouse_create';
export const WAREHOUSE_UPDATE = 'warehouse_update';
export const ORDER_PRODUCT_SCAN_LIST = 'order_product_scan_list';

/************************************************************/

export const WHATSAPP_CONFIRMATION_TEMPLATE_LIST = 'whatsapp_confirmation_template_list';
export const WHATSAPP_CONFIRMATION_TEMPLATE_CONFIGURE = 'whatsapp_confirmation_template_configure';

/************************************************************/

export const INV_PURCHASE_ORDER_LIST = 'inv_purchase_order_list';
export const INV_PURCHASE_ORDER_CONFIGURE = 'inv_purchase_order_configure';
export const INV_INVENTORY_CHECK_LIST = 'inv_inventory_check_list';
export const INV_INVENTORY_CHECK_CONFIGURE = 'inv_inventory_check_configure';
export const INV_SUPPLIER_LIST = 'supplier_list';
export const INV_INBOUND_LIST = 'inbound_list';

/************************************************************/

export const CONTRIB_MARGIN_CM1_LIST = 'contrib_margin_cm1_list';

/************************************************************/

export const USER_LIST = 'user_list';
export const USER_CREATE = 'user_create';
export const USER_UPDATE = 'user_update';
export const ACCOUNT_ACTIVATION_DEACTIVATION = 'account_activation_deactivation';

/************************************************************/

export const ROLE_LIST = 'role_list';
export const ROLE_CONFIGURE = 'role_configure';

/************************************************************/

export const SCOPE_LIST = 'scope_list';

/************************************************************/
export const LIST_SUPPLIER = "list_supplier";
export const CREATE_SUPPLIER = "create_supplier";
export const UPDATE_SUPPLIER = "create_supplier";
export const LIST_PURCHASE_ORDER = "list_purchase_order";
export const CREATE_PURCHASE_ORDER = "create_purchase_order";
export const UPDATE_PURCHASE_ORDER = "update_purchase_order";
export const LIST_PURCHASE_RETURN_REQUEST = "list_purchase_return_request";
export const CREATE_PURCHASE_RETURN_REQUEST = "create_purchase_return_request";
export const UPDATE_PURCHASE_RETURN_REQUEST = "update_purchase_return_request";
export const APPROVE_PURCHASE_RETURN_REQUEST = "approve_purchase_return_request";
export const LIST_PURCHASE_RETURN = "list_purchase_return";
export const CREATE_PURCHASE_RETURN = "create_purchase_return";
export const UPDATE_PURCHASE_RETURN = "update_purchase_return";
export const APPROVE_PURCHASE_RETURN = "approve_purchase_return";
export const LIST_INBOUND = "list_inbound";
export const CREATE_INBOUND = "create_inbound";
export const UPDATE_INBOUND = "update_inbound";
export const CREATE_INBOUND_SUSULAN = "create_inbound_susulan";
export const CREATE_INBOUND_EXTRA = "create_inbound_extra";
export const LIST_QC = "list_qc";
export const CREATE_QC = "create_qc";
export const UPDATE_QC = "update_qc";
export const LIST_OUTBOUND = "list_outbound";
export const CREATE_OUTBOUND = "create_outbound";
export const UPDATE_OUTBOUND = "update_outbound";
export const CREATE_OUTBOUND_SUSULAN = "create_outbound_susulan";
export const CREATE_OUTBOUND_EXTRA = "create_outbound_extra";
export const LIST_INVENTORY = "list_inventory";
export const LIST_INVENTORY_ADJUSTMENT = "list_inventory_adjustment";
export const CREATE_INVENTORY_ADJUSTMENT = "create_inventory_adjustment";
export const UPDATE_INVENTORY_ADJUSTMENT_STATUS = "update_inventory_adjustment_status";
export const MANAGE_INVENTORY_ADJUSTMENT_DOCUMENT_SCHEDULERS = "manage_inventory_adjustment_document_schedulers";
export const INVENTORY_ADJUSTMENT_DOCUMENT_ITEMS_SECRET_KEY = "inventory_adjustment_document_items_secret_key";
export const INVENTORY_ADJUSTMENT_APPROVAL_READ = "inventory_adjustment_approval_read";
export const INVENTORY_ADJUSTMENT_APPROVAL_UPDATE = "inventory_adjustment_approval_update";
export const UPDATE_INBOUND_STATUS = "update_inbound_status";
export const UPDATE_OUTBOUND_STATUS = "update_outbound_status";
export const UPDATE_CONVERSION_STATUS = "update_conversion_status";
export const UPDATE_DEFECT_STATUS = "update_defect_status";
export const UPDATE_ADJUSTMENT_STATUS = "update_adjustment_status";
export const UPDATE_GR_PAYMENT = "update_gr_payment";
export const LIST_RECIPIENT_INVENTORY = "list_recipient_inventory";
export const UPDATE_RECIPIENT_INVENTORY = "update_recipient_inventory";
export const LIST_INVENTORY_FULFILLMENT_DEFECT = "list_inventory_fulfillment_defect";
export const UPDATE_INVENTORY_FULFILLMENT_DEFECT = "update_inventory_fulfillment_defect";
export const CREATE_INVENTORY_FULFILLMENT_DEFECT = "create_inventory_fulfillment_defect";
export const LIST_INVENTORY_PRODUCTION_RESTOCK = "list_inventory_production_restock";
export const UPDATE_INVENTORY_PRODUCTION_RESTOCK = "update_inventory_production_restock";
export const CREATE_INVENTORY_PRODUCTION_RESTOCK = "create_inventory_production_restock";
export const LIST_FORECAST_SAME_DAY_DELIVERY = "list_forecast_same_day_delivery";
export const DELETE_FORECAST_SAME_DAY_DELIVERY = "delete_forecast_same_day_delivery";
export const UPDATE_FORECAST_SAME_DAY_DELIVERY = "update_forecast_same_day_delivery";
export const CREATE_FORECAST_SAME_DAY_DELIVERY = "create_forecast_same_day_delivery";
export const LIST_ITEM_DIMENSION = "list_item_dimension";
export const UPDATE_INBOUND_ITEM_ITOOLS = "update_inbound_item_itools";
export const MANAGE_TRANSFER_ALLOCATIONS = "manage_transfer_allocations";
export const MANAGE_GENERAL_PICK_LIST_ITEM_BULK = "manage_general_pick_list_item_bulk";
export const READ_INVENTORY_QUANTITY = "read_inventory_qty";

/************************************************************/

export const CONFIGURE_SHIPPING_POINT = "configure_shipping_point"
export const LIST_SHIPPING_POINT = "list_shipping_point"
export const CHECK_LOCATION_COVERED_BY_SHIPPING_POINT = "check_location_covered_by_shipping_point"
export const CONFIGURE_SHIPPING_POINT_GLOBAL_CONFIG = "configure_shipping_point_global_config"

/************************************************************/

export const MANAGE_FREEBIE_PRODUCT = 'manage_freebie_product';
export const MANAGE_FREEBIE_PROMO = 'manage_freebie_promo';
export const MANAGE_SELECTABLE_FREEBIE_PROMO = 'manage_selectable_freebie_promo';

/************************************************************/

export const AGENT_TRANSACTION_LIST = "agent_transaction_list"
export const AGENT_TRANSACTION_CONFIGURE = "agent_transaction_configure"
/************************************************************/

export const MANAGE_PROMO_EVENT = 'manage_promo_event';

/************************************************************/

export const CONFIGURE_COURIER = "configure_courier"
export const LIST_COURIER = "list_courier"
export const CONFIGURE_COURIER_FEE = "configure_courier_fee"
export const COURIER_BULK_CREATE_UPDATE = "courier_bulk_create_update"
export const COURIER_PUNISHMENT_MODULE_CONFIG = "courier_punishment_module_config"
export const COURIER_PUNISHMENT_MODULE_VIEW = "courier_punishment_module_view"
export const COURIER_PUNISHMENT_TYPE_READ = "courier_punishment_type_read"
export const COD_PUNISHMENT_CONFIG = "cod_punishment_config"
export const COURIER_PUNISHMENT_TYPE_WRITE = "courier_punishment_type_write"
export const SDD_TRACK_AND_TRACE_OD_READ = "sdd_track_and_trace_od_read"
export const SDD_TRACK_AND_TRACE_OD_WRITE = "sdd_track_and_trace_od_write"
export const SDD_TRACK_AND_TRACE_3PL_OD_WRITE = "sdd_track_and_trace_3pl_od_write"

/************************************************************/

export const LIST_TOTAL_PRICE_LIMIT_WHITELIST = 'list_total_price_limit_whitelist';
export const CONFIGURE_TOTAL_PRICE_LIMIT_WHITELIST = 'configure_total_price_limit_whitelist';

/************************************************************/

export const CX_VERIFICATION = "cx_verification"

/************************************************************/

export const RECALCULATE_ORDER_PIPELINES = "recalculate_order_pipelines"
export const ORDER_PIPELINE_DASHBOARD_LIST = "order_pipeline_dashboard_List"
export const ORDER_PIPELINE_DASHBOARD_CONFIGURE = "order_pipeline_dashboard_configure"
export const ORDER_PIPELINE_LOG_LIST = "order_pipeline_log_list"
export const ORDER_PIPELINE_LOG_CONFIGURE = "order_pipeline_log_configure"

/************************************************************/

export const ORDER_DELIVERY_LIST = "order_delivery_list"
export const ORDER_DELIVERY_CONFIGURE = "order_delivery_configure"
export const CX_ORDER_DELIVERY_CONFIGURE = "cx_order_delivery_configure"
export const ORDER_DELIVERY_3PL_IDX_CONFIGURE = "order_delivery_3pl_idx_configure";
export const ORDER_DELIVERY_3PL_RARA_CONFIGURE = "order_delivery_3pl_rara_configure";

/************************************************************/

export const SDD_ORDER_DELIVERY_DASHBOARD = "sdd_order_delivery_dashboard";
export const VIEW_SDD_ORDER_DELIVERY_DASHBOARD = "view_sdd_order_delivery_dashboard";

/************************************************************/

export const ADDRESS_VERIFICATION_LIST = "address_verification_list"
export const ADDRESS_VERIFICATION_CONFIGURE = "address_verification_configure"

/************************************************************/

export const CATEGORIZATION_LIST = "categorization_list"
export const CATEGORIZATION_CONFIGURE = "categorization_configure"

/************************************************************/

export const MANAGE_POPUPS = "manage_popups"

/************************************************************/

export const SAME_DAY_ORDER_LIMITER_LIST = "same_day_order_limiter_list"
export const SAME_DAY_ORDER_LIMITER_CONFIGURE = "same_day_order_limiter_configure"
export const NDD_ORDER_LIMITER_LIST = "ndd_order_limiter_list";
export const NDD_ORDER_LIMITER_CONFIGURE = "ndd_order_limiter_configure";

/************************************************************/

export const OUT_OF_STOCK_LIST = "out_of_stock_list";
export const OUT_OF_STOCK_CONFIGURE = "out_of_stock_configure";

/************************************************************/

export const MANAGE_NEGATIVE_PRICE = "manage_negative_price";

/************************************************************/

export const MANAGE_HARGA_GILA = "manage_harga_gila";
export const MANAGE_SEO_MAPPING = "manage_seo_mapping"

export const MANAGE_REFERRAL = "manage_referral";

export const PLANOGRAM_CONFIGURE = "planogram_configure";
export const PLANOGRAM_ITEM_CONFIGURE = "planogram_item_configure";
export const PLANOGRAM_VIEW = "planogram_view";
export const PLANOGRAM_ITEM_VIEW = "planogram_item_view";
export const PLANOGRAM_ITEM_RELATIONSHIP_VIEW = "planogram_item_relationship_view";
export const PLANOGRAM_ITEM_RELATIONSHIP_CONFIGURE = "planogram_item_relationship_configure";

/************************************************************/

export const PRODUCT_CATALOG_PROMO_CONFIGURE = "product_catalog_promo_configure";

/************************************************************/

export const LIST_REFUNDS = "list_refunds";
export const MANAGE_REFUND_LIST = "manage_refund_list";
export const VIEW_REFUND_LIST = "view_refund_list";

/**************************Order Queue Configuration**********************************/

export const SPECIAL_TREATMENT_CONFIGURE = 'special_treatment_configure';
export const ORDER_QUEUE_CONFIGURE = 'order_queue_configure';

/**************************Segari Coin Transaction**********************************/

export const MANAGE_SEGARI_COIN_TRANSACTION = "manage_segari_coin_transaction";
export const READ_SEGARI_COIN_TRANSACTION = "read_segari_coin_transaction";

/************************************************************/

export const COMMISSION_GROUP_UPDATE = 'commission_group_update';
export const COMMISSION_GROUP_VIEW = 'commission_group_view';

/************************************************************/

export const COMMISSION_SCHEME_UPDATE = 'commission_scheme_update';
export const COMMISSION_SCHEME_VIEW = 'commission_scheme_view';

/*************************General Pick List**********************************/
export const CREATE_GENERAL_PICK_LIST = 'create_general_pick_list';
export const VIEW_GENERAL_PICK_LIST = 'view_general_pick_list';
export const UPDATE_GENERAL_PICK_LIST = 'update_general_pick_list';
export const DELETE_GENERAL_PICK_LIST = 'delete_general_pick_list';
/************************************************************/

/// DEPRECATED
export const MANAGE_FLASH_SALE_WIDGET = "manage_flash_sale_widget";


/******************************Homepage Management******************************/
export const MANAGE_THEMATIC_WIDGET = "manage_thematic_widget";
export const MANAGE_THEMATIC_BANNER = "manage_thematic_banner";
export const MANAGE_HOMEPAGE_TAB = "manage_homepage_tab";
export const MANAGE_CAROUSEL_BANNER = "manage_homepage_tab";
export const MANAGE_PERMANENT_BANNER = "manage_permanent_banner";

/************************************************************/

export const PRICING_SCHEDULER = 'pricing_scheduler';

/************************************************************/

export const VIEW_WAREHOUSE_DELIVERY_PROPORTION = 'view_warehouse_delivery_proportion';
export const UPDATE_WAREHOUSE_DELIVERY_PROPORTION = 'update_warehouse_delivery_proportion';

/******************************SDD Auto Dashboard******************************/

export const SDD_AUTO_DASHBOARD = 'sdd_auto_dashboard'
export const SDD_AUTO_CANCELLATION_DASHBOARD = 'sdd_auto_cancellation_dashboard'
export const SDD_REASSIGN_ALL = 'sdd_reassign_all'
export const SDD_REASSIGN_CRITICAL = 'sdd_reassign_critical'
export const SDD_TRIAL_TOGGLE = 'sdd_trial_toggle'
export const SDD_AUTO_DASHBOARD_ASSIGNMENT_METRICS_WRITE = 'sdd_auto_dashboard_assignment_metrics_write'
export const SDD_AUTO_DASHBOARD_ASSIGNMENT_METRICS_READ = 'sdd_auto_dashboard_assignment_metrics_read'
export const SDD_AUTO_CANCELLATION_DASHBOARD_WRITE = 'sdd_auto_cancellation_dashboard_write';
export const SDD_AUTO_CANCELLATION_DASHBOARD_READ = 'sdd_auto_cancellation_dashboard_read';

/************************************************************/

export const MANAGE_WEB_ONLY_CAMPAIGN = "manage_web_only_campaign"

/************************************************************/

export const LIMIT_PLANOGRAM_WHITELIST_READ = "limit_planogram_whitelist_read"
export const LIMIT_PLANOGRAM_WHITELIST_EDIT = "limit_planogram_whitelist_edit"
export const LIMIT_PLANOGRAM_WHITELIST_DELETE = "limit_planogram_whitelist_delete"
export const LIST_SUPPLIER_ONBOARD = "supplier_onboard_list";

/************************************************************/

export const PRODUCT_SUGGESTIONS_LIST = "product_suggestions_list"

/***************************Force Majeures*********************************/

export const MANAGE_FORCE_MAJEURES = "manage_force_majeures";
export const MANAGE_FORCE_MAJEURE_CONFIRMATIONS = "manage_force_majeure_confirmations";
export const MANAGE_FORCE_MAJEURE_CANCELLATIONS = "manage_force_majeure_cancellations";

/************************************************************/

export const MANAGE_RECIPE = 'manage_recipe'

/***************** COD ORDER PAYMENT *********************/

export const COD_ORDER_VIEW_ONLY = "cod_order_view_only";
export const COD_ORDER_VERIFY = "cod_order_verify";
export const COD_ORDER_CONFIRM = "cod_order_confirm";

/************************************************************/
export const DROP_ORDER_METRICS = 'drop_order_metrics';

/****************************** PROMO LABEL ******************************/

export const MANAGE_PROMO_LABELS = 'manage_promo_labels';

/***********Manual Curation Items Product Recomm*************/
export const MANAGE_MANUAL_CURATION_ITEMS_RECOMM = 'manage_manual_curation_items_recomm'

/************************************************************/

export const PRODUCT_LABEL_LIST = "product_label_list";
export const PRODUCT_LABEL_CREATE = "product_label_create";
export const PRODUCT_LABEL_UPDATE = "product_label_update";
export const PRODUCT_LABEL_LOG = 'product_label_log';

/************************************************************/

export const SLA_CONFIG_READ = "sla_config_read";
export const SLA_CONFIG_WRITE = "sla_config_write";

/****************************** ITEM CREATION ******************************/

export const CREATE_ITEM_CODE = "create_item_code";
export const CREATE_ITEM_NAME = "create_item_name";
export const CREATE_CATEGORY = "create_category";
export const CREATE_PURCHASE_VALUE = "create_purchase_value";
export const CREATE_INVENTORY_VALUE = "create_inventory_value";
export const CREATE_SELLING_VALUE = "create_selling_value";
export const CREATE_UNIT_OF_MEASUREMENT = "create_unit_of_measurement";
export const CREATE_RELATION_MAPPING = "create_relation_mapping";
export const CREATE_ITEM_STATUS = "create_item_status";
export const CREATE_TAXABLE_GOODS = "create_taxable_goods";
export const CREATE_TAXABLE_PERCENTAGE = "create_tax_percentage";
export const CREATE_IMAGE_URL = "create_image_url";
export const CREATE_LABEL = "create_label";
export const CREATE_ITEM_TAG = "create_item_tag";
export const CREATE_ITEM_NOTES = "create_item_notes";
export const CREATE_ITEM_DESCRIPTION = "create_item_description";
export const CREATE_INTERNAL_NAME = "create_internal_name";
export const CREATE_WAREHOUSE_TAG = "create_warehouse_tag";
export const CREATE_SHELF_LIFE = "create_shelf_life";
export const CREATE_BEST_BEFORE_DATE_TYPE = "create_best_before_date_type";
export const CREATE_INTERNAL_NOTES = "create_internal_notes";
export const CREATE_UNIVERSAL_BARCODE = "create_universal_barcode";
export const CREATE_ITEM_DIMENSION = "create_item_dimension";
export const CREATE_SALES_BOM = "create_sales_bom";
export const UPDATE_ITEM_CODE = "update_item_code";
export const UPDATE_ITEM_NAME = "update_item_name";
export const UPDATE_CATEGORY = "update_category";
export const UPDATE_PURCHASE_VALUE = "update_purchase_value";
export const UPDATE_INVENTORY_VALUE = "update_inventory_value";
export const UPDATE_SELLING_VALUE = "update_selling_value";
export const UPDATE_UNIT_OF_MEASUREMENT = "update_unit_of_measurement";
export const UPDATE_RELATION_MAPPING = "update_relation_mapping";
export const UPDATE_ITEM_STATUS = "update_item_status";
export const UPDATE_TAXABLE_GOODS = "update_taxable_goods";
export const UPDATE_TAX_PERCENTAGE = "update_tax_percentage";
export const UPDATE_IMAGE_URL = "update_image_url";
export const UPDATE_LABEL = "update_label";
export const UPDATE_ITEM_TAG = "update_item_tag";
export const UPDATE_ITEM_NOTES = "update_item_notes";
export const UPDATE_ITEM_DESCRIPTION = "update_item_description";
export const UPDATE_INTERNAL_NAME = "update_internal_name";
export const UPDATE_ITEM_DIMENSION = "update_item_dimension";
export const UPDATE_WAREHOUSE_TAG = "update_warehouse_tag";
export const UPDATE_SHELF_LIFE = "update_shelf_life";
export const UPDATE_BEST_BEFORE_DATE_TYPE = "update_best_before_date_type";
export const UPDATE_INTERNAL_NOTES = "update_internal_notes";
export const UPDATE_UNIVERSAL_BARCODE = "update_universal_barcode";
export const ITEM_LIST_VIEW = "item_list_view";
export const CREATE_PRODUCT_LABEL = "create_product_label";
export const CREATE_NEW_LABEL_VALUE = "create_new_label_value";
export const CREATE_PRODUCT_COLUMN = "create_product_column";
export const CREATE_PRODUCT_DETAIL = "create_product_detail";
export const CREATE_FEATURED_BANNER = "create_featured_banner";
export const CREATE_GUARANTEE_BANNER = "create_guarantee_banner";
export const UPDATE_PRODUCT_LABEL = "update_product_label";
export const UPDATE_NEW_LABEL_VALUE = "update_new_label_value";
export const UPDATE_PRODUCT_COLUMN = "update_product_column";
export const UPDATE_PRODUCT_DETAIL = "update_product_detail";
export const UPDATE_FEATURED_BANNER = "update_featured_banner";
export const UPDATE_GUARANTEE_BANNER = "update_guarantee_banner";


/****************************************************************************************/

export const MANAGE_LANDING_PAGE = 'manage_landing_page';

/************************************************************/

export const COURIER_NOTIFICATION_SCHEDULE_WRITE = 'courier_notification_schedule_write';
export const COURIER_NOTIFICATION_SCHEDULE_READ = 'courier_notification_schedule_read';

export const CREATE_INVENTORY_ADJUSTMENT_SCHEDULER = "create_inventory_adjustment_scheduler";
export const LIST_INVENTORY_ADJUSTMENT_SCHEDULER = "list_inventory_adjustment_scheduler";
export const UPDATE_INVENTORY_ADJUSTMENT_SCHEDULER = "update_inventory_adjustment_scheduler";
/************************************************************/

export const LIST_WAREHOUSE_TRANSFER_ALLOCATOR = "list_warehouse_transfer_allocator";

/************************************************************/

export const MANAGE_ORDER_BLACKLIST = 'manage_order_blacklist';

export const MANAGE_PAYMENT_CAP_CONFIG = 'manage_payment_cap_config';

export const MANAGE_PAYMENT_CONFIG = 'manage_payment_config';

/************************************************************/

export const SALES_FORECAST_VIEW = 'sales_forecast_view';
export const SALES_FORECAST_CONFIGURE = 'sales_forecast_configure';

export const PRODUCTION_MAX_VIEW = 'production_max_view';
export const PRODUCTION_MAX_CONFIGURE = 'production_max_configure';

/****************************************************************************************/

export const MANAGE_ADDRESS_REMINDER = 'manage_address_reminder';

/************************************************************/
export const ITEM_QR_LABEL_ITOOLS = 'item_qr_label_itools';

/************************************************************/

export const COURIER_KYC_WRITE = 'courier_kyc_write';
export const COURIER_KYC_READ = 'courier_kyc_read';

/************************************************************/

export const COURIER_AVAILABILITY_READ = 'courier_availability_read';

/************************************************************/
export const ITEM_DRAFT_CONFIGURE = 'item_draft_configure';
export const ITEM_DRAFT_EXECUTE = 'item_draft_execution';

/************************************************************/

export const PACKAGE_CONFIGURE = 'package_configure';

export const LABEL_GROUP_CREATE = 'label_group_create';
export const LABEL_GROUP_UPDATE = 'label_group_update';

/************************************************************/

export const VIEW_SUPPLY_LEAD_TIME = 'view_supply_lead_time';
export const CONFIGURE_SUPPLY_LEAD_TIME = 'configure_supply_lead_time';

export const VIEW_SUPPLY_DELIVERY_SCHEDULE = 'view_supply_delivery_schedule';
export const CONFIGURE_SUPPLY_DELIVERY_SCHEDULE = 'configure_supply_delivery_schedule';
/************************************************************/

export const CONFIGURE_PROMO_QUOTA = "configure_promo_quota";

export const DAYS_OF_INVENTORY_CREATE = 'create_days_of_inventory';
export const DAYS_OF_INVENTORY_UPDATE = 'update_days_of_inventory';
/************************************************************/

export const CONFIGURE_STOCK_GROUP_MAPPING = "configure_stock_group_mapping";

export const EXPIRATION_DATE_CREATE = 'create_expiration_date';
export const EXPIRATION_DATE_CONFIGURE = 'configure_expiration_date';



/************************************************************/
export const MULTI_BATCH_PICKLIST_LIST = "multi_batch_picklist_list";
export const MULTI_BATCH_PICKLIST_CREATE_CSV = "multi_batch_picklist_create_csv";

/************************************************************/

/************************************************************/

export const MANAGE_STICKY_VOUCHER = "manage_sticky_voucher";

/************************************************************/


/************************************************************/

export const ITEM_LOCATION_ROUTE_READ = "ilr_read";

/************************************************************/

export const MANAGE_PUSH_NOTIFICATION_IMAGE = "manage_push_notification_image";

/************************************************************/
export const MANAGE_FRAUD_PREVENTION = "manage_fraud_prevention";

/************************************************************/
export const MAINTAIN_OPS_IMAGE = "maintain_ops_image"

/************************************************************/
export const VIEW_AUTOPOL_TRIGGER = "view_autopol_trigger";
export const CONFIGURE_AUTOPOL_TRIGGER = "configure_autopol_trigger";

/************************************************************/

export const MIDMILE_TRANSFER_VIEW = "view_midmile_transfer";
export const MANPOWER_VIEW = "manpower_view";
export const MANPOWER_WRITE_UPDATE = "manpower_write_update";
export const MANPOWER_WHITELIST = "manpower_whitelist";

export const FORECAST_ITEM_RULE_VIEW = 'forecast_item_rule_view';
export const FORECAST_ITEM_RULE_CONFIGURE = 'forecast_item_rule_configure';

/************************************************************/

export const MANAGE_SUBSCRIPTIONS = 'manage_subscriptions';

/************************************************************/

export const MANAGE_PRODUCT_BULKING = 'manage_product_bulking';

/************************************************************/

export const CREATE_UPDATE_RULE_BUILDER = "create_update_rule_builder";
export const VIEW_RULE_BUILDER = "view_rule_builder";

/****************** EXTERNAL CHANNEL ORDER ******************/

export const CREATE_GOMART_MANUAL_ORDER = "create_gomart_manual_order";
export const CREATE_GRABMART_MANUAL_ORDER = "create_grabmart_manual_order";

/************************************************************/

export const MANAGE_AGENT_INFORMATION_BOARD = 'manage_agent_information_board';
/****************************************************************************************/

export const RAW_MATERIAL_REQUEST_VIEW = 'raw_material_request_view';
export const RAW_MATERIAL_REQUEST_CONFIGURE = 'raw_material_request_configure';

export const WAREHOUSE_TRANSFER_CATEGORY_VIEW = "warehouse_transfer_category_view";
export const WAREHOUSE_TRANSFER_CATEGORY_CREATE = "warehouse_transfer_category_create";
export const WAREHOUSE_TRANSFER_CATEGORY_ASSIGN = "warehouse_transfer_category_assign";



/****************** QUALITY CONTROL PRODUCT ******************/

export const QUALITY_CONTROL_PRODUCT_VIEW = "quality_control_product_view";
export const QUALITY_CONTROL_PRODUCT_ACTION = "quality_control_product_action";
export const QUALITY_CONTROL_PRODUCT_UPLOAD = "quality_control_product_upload";

/****************************************************************************************/

/****************** SUPPLIER PROMO CONFIRMATION ******************/
export const MANAGE_SUPPLIER_PROMO_CONFIRMATION = "manage_supplier_promo_confirmation";
export const VIEW_SUPPLIER_PROMO_CONFIRMATION = "view_supplier_promo_confirmation";
export const VIEW_SUPPLIER_PROMO_CONFIRMATION_CONTRACT = "view_supplier_promo_confirmation_contract";
export const APPROVE_SUPPLIER_PROMO_CONFIRMATION = "approve_supplier_promo_confirmation";
export const CLOSE_SUPPLIER_PROMO_CONFIRMATION = "close_supplier_promo_confirmation";
export const MANAGE_INVOICE = "manage_invoice";
export const VIEW_SELLOUT_REPORT_DASHBOARD = "view_sellout_report_dashboard";


/*************************************** POS ********************************************/
export const POS_VIEW_CASH_MUTATION = "pos_view_cash_mutation";
export const POS_CREATE_CASH_MUTATION = "pos_create_cash_mutation";
export const POS_VIEW_MEMBERSHIP_KYC = "pos_view_membership";
export const POS_MANAGE_SPECIAL_PRICE_PROMO = "pos_manage_special_price_promo";
/****************************************************************************************/

/****************** INVENTORY CAPACITY TRANSFER LIMIT ******************/
export const INVENTORY_CAPACITY_TRANSFER_LIMIT_CAPACITY_CONFIGURE = "configure_capacity_ictl";
export const INVENTORY_CAPACITY_TRANSFER_LIMIT_PROPOSED_TSL_CONFIGURE = "configure_proposed_tsl_ictl";