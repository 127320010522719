import React, { useState, useEffect } from 'react';
import styles from "../style.module.scss";
import * as warehouseTagGroupApi from "../../../../../apis/warehouseTagGroup.js";
import * as shipmentApi from "../../../../../apis/shipment.js";
import ShipmentSearchBar from './ShipmentSearchBar.js';
import ShipmentTable from './ShipmentTable.js';

const _ = require('lodash');
const ShipmentListPage = (props) => {
    const { setSelectedShipmentId, setSelectedShipmentName } = props;
    const [shipmentList, setShipmentList] = useState([]);
    const [ warehouseTagGroupFunctionalityOptions, setWarehouseTagGroupFunctionalityOptions ] = useState([]);
    const [ isFetchingShipments, setIsFetchingShipments ] = useState(false);

    useEffect(() => {
        if (warehouseTagGroupFunctionalityOptions.length === 0) {
            fetchWarehouseTagGroupFunctionalityOptions();
        }
    }, []);

    const fetchWarehouseTagGroupFunctionalityOptions = async () => {
        try {
            warehouseTagGroupApi.getAllWarehouseGroupFunctionalityEnumTypes().then((result) => {
                setWarehouseTagGroupFunctionalityOptions(result?.data?.data);
            }).catch(err => {
                throw err;
            });
        } catch (e) {
            props.displayAlert({ message: e.message, type: "error" });
        }
    }

    const fetchShipments = async (warehouseTagGroupFunctionalityEnum) => {
        if (_.isNil(warehouseTagGroupFunctionalityEnum)) {
            setShipmentList([])

            return;
        }

        setIsFetchingShipments(true);

        try {
            const params = {
                functionality: warehouseTagGroupFunctionalityEnum
            }
            const {data} = await shipmentApi.getAll(params)
            setShipmentList(data?.data)
        } catch (e) {
            props.displayAlert({ message: e.message, type: "error" });
        }

        setIsFetchingShipments(false);
    }

    const Title = () => {
        return (
            <div className={styles.title} style={{fontSize: '38px'}}>Shipment</div>
        );
    }

    return (
        <div className={"w-100 h-100 overflow-auto"}>
            <Title/>

            <ShipmentSearchBar
                warehouseTagGroupFunctionalityOptions={warehouseTagGroupFunctionalityOptions}
                fetchShipments={fetchShipments}
            />

            <ShipmentTable
                shipmentList={shipmentList}
                setSelectedShipmentId={setSelectedShipmentId}
                setSelectedShipmentName={setSelectedShipmentName}
                isLoading={isFetchingShipments}
            />
        </div>
    );
}


export default ShipmentListPage;