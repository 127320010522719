import fileDownload from "js-file-download";
import * as qs from "query-string";
import {
    BULK_PRODUCT_V1,
    BULK_PRODUCT_V1_1,
    ITEM_DRAFTS_V1,
    OPERATION_LOGS_V1_URL,
    PRODUCTS_V1,
    PRODUCTS_V1_1,
    PRODUCTS_V1_BY_PRODUCT_TAG_ID,
    PRODUCT_BY_SKU,
    PRODUCT_BY_SKU_V1,
    PRODUCT_CATEGORIES_V1,
    PRODUCT_CONVERSION_V1,
    PRODUCT_DROPDOWN_V1,
    PRODUCT_KEYWORDS_V1,
    PRODUCT_KEYWORDS_V1_BULK_CREATE,
    PRODUCT_KEYWORDS_V1_BULK_DELETE,
    PRODUCT_KEYWORDS_V1_KEYWORD_ID,
    PRODUCT_KEYWORD_LOGS_V1,
    PRODUCT_LIST_ITOOLS,
    PRODUCT_LOGS_V1,
    PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE,
    PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE_PREVIEW,
    PRODUCT_ORDER_LIMITS_BULK_DELETE_V1,
    PRODUCT_ORDER_LIMITS_BULK_OOS_OVERRIDE_V1,
    PRODUCT_ORDER_LIMITS_V1,
    PRODUCT_ORDER_LIMITS_V1_ID,
    PRODUCT_ORDER_LIMIT_OOS_OVERRIDE_V1,
    PRODUCT_ORDER_LIMIT_REMOVABLES_V1,
    PRODUCT_ORDER_LIMIT_USAGE_V1,
    PRODUCT_ORDER_LIMIT_WAREHOUSE_TRANSFER_V1,
    PRODUCT_PRICES_V_1,
    PRODUCT_SEO_RESET,
    PRODUCT_WITH_PURCHASING_PRODUCT,
    UNIVERSAL_BARCODE,
    EXPIRATION_DATE,
    PRODUCT_BRANDS,
    PRODUCT_SPECIAL_KEYWORD_V1,
    PRODUCT_DROPDOWN_V1_1,
    EXTERNAL_CHANNEL_PRODUCTS,
} from "../constants/api";
import {
    PRODUCTS_DEFAULT_LIMIT
} from "../constants/requestSizeLimit";
import customAxios from "./axios";

export function getProductPrices(params) {
    return customAxios.get(PRODUCT_PRICES_V_1, {params})
}

export function getProductDropdown({size, sort, isActiveOnly = false}) {
    const params = {size, sort, isActiveOnly}
    return customAxios.get(PRODUCT_DROPDOWN_V1, {params});
}

export function getProductBySku(sku) {
    const encodedSku = encodeURI(sku);

    return customAxios.get(PRODUCT_BY_SKU(encodedSku));
}

export function getProductsLight({isActiveOnly = false, isStorableOnly = false, specialTypeOnly = false, isSellableOnly= false} = {}) {
    const params = {size: PRODUCTS_DEFAULT_LIMIT, isActiveOnly, isStorableOnly, specialTypeOnly, isSellableOnly}

    return customAxios.get(PRODUCTS_V1_1, {params})
}

export function getProductDropdownV2({isActive, isStorable, isSellable, isPurchasable, isSpecialTypeOnly, size = PRODUCTS_DEFAULT_LIMIT, sort} = {}) {
    const params = {size, sort, isActive, isStorable, isSellable, isPurchasable, isSpecialTypeOnly}

    return customAxios.get(PRODUCT_DROPDOWN_V1_1, {params})
}

export function getProductDropdownWithWhTag() {
    return customAxios.get(PRODUCT_DROPDOWN_V1_1 + '/with-wh-tag')
}

export function getProductsBySkuId({params}) {
    return customAxios.get(PRODUCT_BY_SKU_V1, {params})
}

export function getProductsByProductTagIds(params) {
    return customAxios.get(PRODUCTS_V1_BY_PRODUCT_TAG_ID, {params})
}

export function getProductsWithPurchasingProduct({size}) {
    const params = {size}
    return customAxios.get(PRODUCT_WITH_PURCHASING_PRODUCT, {params})
}

export function updateProductsBySkuId(body) {
    return customAxios.put(PRODUCT_BY_SKU_V1, body)
}

export function resetSeoById(id) {
    return customAxios.put(PRODUCT_SEO_RESET(id))
}

export function getProductOrderLimits(params) {
    return customAxios.get(PRODUCT_ORDER_LIMITS_V1, {
        params,
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        }
    })
}

export function getProductOrderLimitsForWarehouseTransfer(data) {
    return customAxios.post(PRODUCT_ORDER_LIMIT_WAREHOUSE_TRANSFER_V1, data)
}

const MAX_REQUESTS_COUNT = 1
const INTERVAL_MS = 100
let PENDING_REQUESTS = 0
const customQueueableAxios = customAxios;

customQueueableAxios.interceptors.request.use(function (config) {
    return new Promise((resolve, reject) => {
        let interval = setInterval(() => {
            if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                PENDING_REQUESTS++
                clearInterval(interval)
                resolve(config)
            }
        }, INTERVAL_MS)
    })
})

customQueueableAxios.interceptors.response.use(function (response) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.resolve(response)
}, function (error) {
    PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
    return Promise.reject(error)
})

export function getRemovableProductOrderLimits(params) {
    return customAxios.get(PRODUCT_ORDER_LIMIT_REMOVABLES_V1, {
        params,
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        }
    })
}

export function getProductUsageByLimitId(id) {
    return customQueueableAxios.get(PRODUCT_ORDER_LIMIT_USAGE_V1(id))
}

export function createProductOrderLimit(data) {
    return customAxios.post(PRODUCT_ORDER_LIMITS_V1, data)
}

export function patchProductOrderLimit({id, data}) {
    return customAxios.patch(PRODUCT_ORDER_LIMITS_V1_ID({id}), data)
}

export function deleteProductOrderLimitById({id}) {
    return customAxios.delete(PRODUCT_ORDER_LIMITS_V1_ID({id}))
}

export function bulkDeleteProductOrderLimit({data}) {
    return customAxios.post(PRODUCT_ORDER_LIMITS_BULK_DELETE_V1, data)
}

export function overrideOutOfStockProductOrderLimit({id, data}) {
    return customAxios.patch(PRODUCT_ORDER_LIMIT_OOS_OVERRIDE_V1(id), data)
}

export function bulkOverrideOutOfStockProductOrderLimit({data}) {
    return customAxios.post(PRODUCT_ORDER_LIMITS_BULK_OOS_OVERRIDE_V1, data)
}

export function getAllCategories() {
    return customAxios.get(PRODUCT_CATEGORIES_V1);
}

export function createOrUpdateProduct({data}) {
    return customAxios.post(PRODUCTS_V1, data);
}

export function createProduct({data}) {
    return customAxios.post(PRODUCTS_V1_1, data);
}

export function updateProduct({data}) {
    return customAxios.put(PRODUCTS_V1_1, data);
}

export function bulkCreateOrUpdateProducts({data}) {
    return customAxios.post(BULK_PRODUCT_V1, data);
}

export function bulkCreateOrUpdateProductsV1_1({data}) {
    return customAxios.post(BULK_PRODUCT_V1_1, data);
}

export function getCreateOrUpdateProductLogs(params) {
    return customAxios.get(PRODUCT_LOGS_V1, {params});
}

export function getProductKeywords(params) {
    return customAxios.get(PRODUCT_KEYWORDS_V1, {params});
}

export function getProductKeywordLogs(params) {
    return customAxios.get(PRODUCT_KEYWORD_LOGS_V1, {params})
}

export function createProductKeywords(data) {
    return customAxios.post(PRODUCT_KEYWORDS_V1, data);
}

export function deleteOneProductKeywords({id}) {
    return customAxios.delete(PRODUCT_KEYWORDS_V1_KEYWORD_ID({id}));
}

export function deleteBulkProductKeywords({keyword}) {
    return customAxios.delete(PRODUCT_KEYWORDS_V1_BULK_DELETE({keyword}))
}

export function bulkCreateProductKeywords(data) {
    return customAxios.post(PRODUCT_KEYWORDS_V1_BULK_CREATE, data);
}

export function getAllProductForConversion() {
    return customAxios.get(PRODUCT_CONVERSION_V1);
}

export function getBulkCreateUpdateDeleteProductOrderLimitPreview({data}) {
    return customAxios.put(PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE_PREVIEW, data)
}

export function bulkCreateUpdateDeleteProductOrderLimit({data}, params) {
    return customAxios.put(PRODUCT_ORDER_LIMITS_BULK_CREATE_UPDATE_DELETE, data, {params})
}

export function getItoolsProductList(params) {
    return customAxios.get(PRODUCT_LIST_ITOOLS, {params});
}

export function fetchUniversalBarcodes(params){
    return customAxios.get(UNIVERSAL_BARCODE + "/simplified", {params})
}

export function bulkCreateUniversalBarcodes(data) {
    return customAxios.post(UNIVERSAL_BARCODE, data);
}

export function getItemMasterDataBulkCSVRequest(id) {
    return customAxios.get(OPERATION_LOGS_V1_URL + '/item-master-data-bulk-log/' + id, {responseType: 'blob'})
        .then(response => {
            fileDownload(response.data, id + '.csv')
        });
}

export function getItemDraftList(params) {
    return customAxios.get(ITEM_DRAFTS_V1+'/filter', {params});
}

export function getItemDraftSimplifiedList(params) {
    return customAxios.get(ITEM_DRAFTS_V1+'/filter-simplified', {params});
}

export function getDraftBySkuId(skuId) {
    return customAxios.get(ITEM_DRAFTS_V1+'/filter', {
        params: {
            skuIds: skuId
        }
    });
}

export function createDraft({data}) {
    return customAxios.post(ITEM_DRAFTS_V1, data);
}

export function bulkCreateDraft({data}) {
    return customAxios.post(ITEM_DRAFTS_V1+'/bulk', data);
}

export function approveDraft({ draftIds }) {
  return customAxios.post(ITEM_DRAFTS_V1+'/approve', draftIds);
}

export function updateDraft({data}) {
    return customAxios.put(ITEM_DRAFTS_V1, data);
}

export function cancelDraft({draftIds}){
    return customAxios.patch(ITEM_DRAFTS_V1+'/cancel', draftIds);
}

// EXPIRATION DATE
export function getExpirationDateOptions () {
    return customAxios.get(EXPIRATION_DATE+'/get-options');
}

export function getStorageRoomTypeOptions () {
    return customAxios.get(PRODUCTS_V1+'/storage-room/get-options');
}

export function getPackagingTypeOptions () {
    return customAxios.get(PRODUCTS_V1+'/packaging/get-options');
}

export function getShelfLifeType () {
    return customAxios.get(PRODUCTS_V1+'/shelf-life-type/get-options');
}

export function refreshNDDTracker(){
    return customAxios.post(PRODUCT_ORDER_LIMITS_V1 + '/refresh/ndd');
}

export function getProductForLegacy(url) {
    return customAxios.get(url);
}

export function getAllExistingProductBrands() {
    return customAxios.get(PRODUCT_BRANDS);
}

export function getProductsSpecialKeyword(params) {
    return customAxios.get(PRODUCT_SPECIAL_KEYWORD_V1, {params})
}

export function getExternalChannelProducts(externalOrderChannelId) {
    return customAxios.get(EXTERNAL_CHANNEL_PRODUCTS(externalOrderChannelId))
}

// Empty uom type will default to return all
export function getUomUnitOptionsByType (uomType, isForOfflineStore) {
    return customAxios.get(PRODUCTS_V1+'/uom-unit/get-options', {
        params: {
            uomType,
            isForOfflineStore
        }
    });
}

export function getAdditionalItemTypes () {
    return customAxios.get(PRODUCTS_V1+'/additional-product-type/get-options');
}